
import React from 'react'
import { InpostGeowidgetReact } from 'inpost-geowidget-react'

const App = ({setLockerNumber}) => {
  const token = "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJzQlpXVzFNZzVlQnpDYU1XU3JvTlBjRWFveFpXcW9Ua2FuZVB3X291LWxvIn0.eyJleHAiOjIwNDgxMzQyMjYsImlhdCI6MTczMjc3NDIyNiwianRpIjoiNzM3OWZjZjMtMDBiNi00ZWU5LWE5OTktMzc2NWRkZjQxNWJmIiwiaXNzIjoiaHR0cHM6Ly9sb2dpbi5pbnBvc3QucGwvYXV0aC9yZWFsbXMvZXh0ZXJuYWwiLCJzdWIiOiJmOjEyNDc1MDUxLTFjMDMtNGU1OS1iYTBjLTJiNDU2OTVlZjUzNTotR0VWaV8wSFpBSlVJZ1lxUTZWakpTeGVOSmRPRmFTRmhkSUM5ZG8zTHBJIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoic2hpcHgiLCJzZXNzaW9uX3N0YXRlIjoiNDRhNTJiMmItYzgwYi00ZjA0LWFhNjgtNzdmMWZkOWQyZWU5Iiwic2NvcGUiOiJvcGVuaWQgYXBpOmFwaXBvaW50cyIsInNpZCI6IjQ0YTUyYjJiLWM4MGItNGYwNC1hYTY4LTc3ZjFmZDlkMmVlOSIsImFsbG93ZWRfcmVmZXJyZXJzIjoid3d3LnByYWNvd25pYWlza3J5LnBsIiwidXVpZCI6IjFhYjdlZmM5LTA1MDgtNDkxYS05MGQzLWIxMjIzMmVmOWMxYiJ9.pr-k3SFy8aiizrfcHF_bENsGUPnYECr-39jC9qf0Q8Q1PClxtcl92GuFDexNVJFheCNpBIvMyH4hVWncFida-XGF-OzEAhx6umn2iwL1197HIy0VrVayHGDb9n528mRZ1Gj5VL8nzmCnH3_Y0YkBTf0UaA3XB77CBznxrXQesfMA7bwn-tB0TSD2ZW73OkGJY-d9sQES94W2I6ntbv0MbKIWlKk_DGCtWCKne_J31w1PM4Z7xpslEXG2dNDpCB7x6T-F0wSUroXjqy58YQeAiOSgtH1Q7z5bqws8iHuPD8sZVVDdPAZ6QTl7oybtbI1rHdy0NFevjIg0pmxUkKv2OA";     // Generate YOUR_TOKEN on https://manager.paczkomaty.pl (for production environment) or https://sandbox-manager.paczkomaty.pl (for sandbox environment).
  const identifier = 'Geo1';      // Html element identifier, default: 'Geo1'
  const language = 'pl';          // Language, default: 'pl'
  const config = 'parcelcollect'; // Config, default: 'parcelcollect'
  const sandbox = false;          // Run as sandbox environment, default: false

  const apiReady = (api) => {
    // You can also use API Methods, as example
    api.changePosition({ longitude: 20.318968, latitude: 49.731131 }, 8);
    // console.log(api);
  }

  const pointSelect = (point) => {
    setLockerNumber(point.name);
  }

  return <div>
    <div style={{ height: '1000px', width: '1000px', maxWidth: '100vw', marginLeft: "-20px", marginRight: "-20px" }}>
      <InpostGeowidgetReact token={ token } identifier={ identifier } apiReady={ apiReady } pointSelect={ pointSelect } />
    </div>
  </div>
}

export default App