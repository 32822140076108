import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'

export const LinkText = styled(Link)`
    display: block;
    text-decoration: none;
    font-size: 20px;
    color: rgb(158, 143, 122);
    padding: 8px;
    margin: 0;
    transition: 300ms;
    &:hover ${this} {
        padding-left: 15px;
    }
    text-decoration: none;
    transition: 200ms;
    padding: 5px 20px;
    @media (min-width: 850px){
    font-size: 20px;
    border-bottom: rgba(158, 143, 122, ${(props)=> props.chosen ? "1" : "0"}) solid 1px;
    color: rgb(158, 143, 122);
    }
    @media (max-width: 850px){
    color: black;
    text-align: center;
    background-color: rgba(255,255,255,${(props)=> props.chosen ? "0.5" : "0"});
    }
    &:hover ${this} {
    padding-right: 15px;
    padding-left: 25px;
    }
`
const Wrapper = styled.div`
    padding: 10px;
    @media (min-width: 850px){
    border: solid rgb(180, 171, 165) 1px;
    background-color: rgb(248, 246, 244);
    border-radius: 5px;
    }
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    z-index: 1;
`

const categories = ["", "dezodoranty", "mydła", "sole", "serum", "peelingi", "hydrolaty", "masła", "zestawy", "akcesoria"]
const Categories = (props) => {
    const detailsJSON = (useParams()).details
    const { filters, category, } = detailsJSON ? JSON.parse(detailsJSON) : {}
    return (
        <Wrapper>
            {
                categories.map(name =>
                        <LinkText key={name} chosen={category === name}
                            to={`/shop/${JSON.stringify({ category: name, filters })}`}
                        >{name === "" ? "wszystko" : name}</LinkText>
                )
            }
        </Wrapper>
    )
}
export default Categories